html, body, #root
{
  height: 100%;
  width: 100%;
  background-color: #ccc;
}

.App{
  width: 100%;
  height: 100%;
  background-color: lightgray;
}