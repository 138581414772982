.lineform__container {
    margin-top: 1rem;
}

.parent__card {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    background-color: #C0DFD9 !important;
}

.child__card1 {
    width: 49%;
    margin: auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.child__card2 {
    width: 49%;
    margin: auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.head1 {
    text-align: center;
}

.row {
    height: 4.5rem;
    align-items: center;
}

.logo {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
}
.red__name,.yellow__name,.blue__name{
    color:  #76b852;
    text-shadow: 1px 1px 2px black, 0 0 25px white, 0 0 5px white, 0 0 10px white !important;
}
.child__card__paraheading {
    font-weight: 1rem;
    text-align: center;
    margin-top: 0.5rem;
    color:  #76b852;
    text-shadow: 1px 1px 2px black, 0 0 25px white, 0 0 5px white, 0 0 10px white !important;
}

.line__names {
    text-align: center;
}

.form__vr__input,
.form__cr__input,
.form__ar__input,
.form__rr__input,
.form__pr__input {
    border-style: solid;
    border-color: red;
    border-radius: 5rem;
    border-width: 0.2px;
}

.form__vy__input,
.form__cy__input,
.form__ay__input,
.form__ry__input,
.form__py__input {
    border-style: solid;
    border-color: rgb(255, 187, 0);
    border-radius: 5rem;
    border-width: 0.2px;
}

.form__vb__input,
.form__cb__input,
.form__ab__input,
.form__rb__input,
.form__pb__input {
    border-style: solid;
    border-color: blue;
    border-radius: 5rem;
    border-width: 0.2px;
}

.graph__button1 {
    text-align: center !important;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 70%;
    margin-bottom: 5.8rem;
    margin-top: 2rem;
}

.box div input {
    color: blue !important;
    text-align: center;
    font-weight: bolder;
}

.button_txt {
    margin-bottom: 3.5rem;
}
.card_text{
    color:  #76b852;
    text-shadow: 1px 1px 2px black, 0 0 25px white, 0 0 5px white, 0 0 10px white !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .lineform__container {
        margin-top: 13rem;
    }

    .button_txt {
        margin-bottom: 3.5rem !important;
    }

    .parent__card {
        display: flex;
    }

    .child__card1 {
        width: 97%;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .child__card2 {
        width: 97%;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .graph__button1 {
        font-size: 7px !important;
    }

    .card_text {
        font-size: 15px;
        margin: auto;
        font-weight: bolder;
    }
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
    .graph__button1 {
        font-size: 8px !important;
    }
}

@media only screen and (min-width: 912px) and (max-width: 912px) {
    .graph__button1 {
        font-size: 10px !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .graph__button1 {
        font-size: 15px !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}