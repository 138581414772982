.middleinfo__text {
    font-size: 28px;
    width: 100%;
    margin: auto;
    color:  #76b852;
    text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px white, 0 0 10px white !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    
 }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) { }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) { }

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) { }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) { }