.app__bar {
    width: 100%;
    height: 6rem;
    background-color: white;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.rightinfo__container {
    width: 25%;
    color: white;
    background-color: red;
    text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black, 0 0 10px black;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    text-align: center;
}

.middleinfo__container {
    width: 50%;
    color: white;
    text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black, 0 0 10px black;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    text-align: center;
}

.leftinfo__container {
    width: 25%;
    background-color: red;
    height: 100%;
    display: flex;
    align-items: center;
    text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black, 0 0 10px black;
    flex-wrap: nowrap;
    text-align: center;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .app__bar {
        flex-wrap: wrap;
    }

    .rightinfo__container {
        width: 100%;
        text-align: center;
    }

    .middleinfo__container {
        width: 100%;
        background-color: aquamarine;
        text-align: center;
    }

    .leftinfo__container {
        width: 100%;
        background-color: #ef9273;
        text-align: center;
    }
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
    .app__bar {
        width: 100%;
        height: 6rem;
        background-color: white;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }

    .rightinfo__container {
        width: 30%;
        color: white;
        background-color: red;
        text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black, 0 0 10px black;
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        text-align: center;
    }

    .middleinfo__container {
        width: 40%;
        color: white;
        text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black, 0 0 10px black;
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        text-align: center;
    }

    .leftinfo__container {
        width: 30%;
        background-color: red;
        height: 100%;
        display: flex;
        align-items: center;
        text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black, 0 0 10px black;
        flex-wrap: nowrap;
        text-align: center;
    }

}

@media only screen and (min-width: 653px) and (max-width: 740px) {
    .app__bar {
        width: 100%;
        height: 6rem;
        background-color: white;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }

    .rightinfo__container {
        width: 30%;
        color: white;
        background-color: red;
        text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black, 0 0 10px black;
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        text-align: center;
    }

    .middleinfo__container {
        width: 40%;
        color: #76b852;
        text-shadow: 1px 1px 2px white, 0 0 25px white, 0 0 5px white, 0 0 10px white !important;
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        text-align: center;
    }

    .leftinfo__container {
        width: 30%;
        background-color: red;
        height: 100%;
        display: flex;
        align-items: center;
        text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black, 0 0 10px black;
        flex-wrap: nowrap;
        text-align: center;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}