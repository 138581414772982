.custom__modal{
    width: 55% !important;
    height: 100% !important;
}
.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    justify-items: center;
  }
  
  .grid-item {
    width: 100%;
    height: 100%;
  }
  
  .grid-item.center {
    grid-column: 1 / span 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media screen and (max-width: 768px) {
    .grid-container {
      display: block;
      overflow-x: auto;
    }
    
    .grid-item {
      margin-bottom: 20px;
    }
    
    .grid-item.center {
      margin-left: 7.5rem;
    }
  }
  @media only screen and (min-width: 653px) and (max-width: 740px) {
    .grid-container {
      display: block;
      overflow-x: auto;
    }
    
    .grid-item {
      margin-bottom: 20px;
    }
    
    .grid-item.center {
      margin-left: 5.5rem;
    }
  }
  @media only screen and (min-width: 844px) and (max-width: 900px){
    .grid-container {
      display: block;
      overflow-x: auto;
    }
    
    .grid-item {
      margin-bottom: 20px;
    }
    
    .grid-item.center {
      margin-left: 2.5rem;
    }
  }
 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 768px) and (max-width: 820px) {
  .grid-container {
    display: block;
    overflow-x: auto;
  }
  
  .grid-item {
    margin-bottom: 20px;
  }
  
  .grid-item.center {
    margin-left: 3.5rem;
  }
}
@media only screen and (min-width: 901px) and (max-width: 920px){
  .grid-container {
    display: block;
    overflow-x: auto;
  }
  .grid-item.center {
    margin-left: 1rem;
  }
}
@media only screen and (min-width: 1368px) and (max-width: 1368px) {
  .grid-container{
    overflow-x: auto;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .custom__modal{
    width: 55% !important;
    height: 100% !important;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    justify-items: center;
    overflow-x: auto;
  }
  
  .grid-item {
    width: 100%;
    height: 100%;
  }
  
  .grid-item.center {
    grid-column: 1 / span 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}  
