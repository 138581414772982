.marquee{
    color: white;
    background-color: black;
    height: 2.5rem;
    text-align: center;
    align-items: center;
    justify-content:center;
    padding-top: 5px;
    text-transform: uppercase;
}
